import React from "react";

const Auth = () => (
  <div>
    <h3>Testing Auth</h3>
    <p> Some Auth</p>
  </div>
);

export default Auth;
